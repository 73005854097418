import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import { connect } from "react-redux"
import Img from "gatsby-image"
import Modal from "../components/modal"
import HeaderDecider from "../components/headerDecider"
import Jurg from "../components/work/jurg"

const Work = (props) => {

    useEffect(() => {
        // Add class to body
        document.body.classList.add('white-background');
        // Cleanup function to remove the class when the component unmounts
        return () => {
            document.body.classList.remove('white-background');
        };
    }, []);

    //this one toggles the modal display
    const [modal, display] = useState(false)
    //this one gets the chosen embed for the non-mobile grids
    const [embed, choose] = useState()
    //this one gets the info for the mobile grids
    const [mobileInf, info] = useState({})
    const [jurg, displayM6] = useState(false)

    const winWidth = props.width
    const winHeight = props.height

    //style for general css grid
    let gridStyle = {
        display: `grid`,
        gridTemplateColumns: `${winWidth / 3}px ${winWidth / 3}px ${winWidth / 3}px`,
        position: `relative`
    }

    //style for each block
    let innerDivStyle = {
        position: `relative`,
        width: winWidth / 3,
        height: winHeight / 3,
    }

    let picStyle= {
        maxHeight: `100%`,
    }   

    let picCont = {
        display: `flex`,
        justifyContent: `center`,
        height: winHeight / 3,
        overflow: `none`,
    }

    //these are both not consts because they change depending on screen size^^^

    //for mid size:
    if (winWidth > 576 && winWidth <= 769) {

        gridStyle = {
            ...gridStyle,
            gridTemplateColumns: `${winWidth / 2}px ${winWidth / 2}px`,
        }
    
        innerDivStyle = {
            ...innerDivStyle,
            width: winWidth / 2,
            height: winHeight / 3,
        }

        picStyle= {
            maxHeight: `100%`,
        }   

        picCont = {
            display: `flex`,
            justifyContent: `center`,
            height: winHeight / 3,
            overflow: `none`,
        }
    
    //for small size:
    } else if (winWidth <= 576) {

        gridStyle = {
            ...gridStyle,
            gridTemplateColumns: `${winWidth}px`,
        }
    
        innerDivStyle = {
            ...innerDivStyle,
            width: winWidth,
            height: `auto`
        }

        picStyle= {
            maxHeight: `100%`,
        }   

        picCont = {
            display: `flex`,
            justifyContent: `center`,
            maxHeight: winHeight / 3,
            overflow: `none`,
        }
    
    } 

    const modalOn = emby => event => {

        event.preventDefault()

        if ({modal}.modal == false) {
          choose(emby.html)
          info(emby.frontmatter)
          display(true)
        } else {
          display(false)
        }
    
    }

    const modalOff = (event) => {
        event.preventDefault()

        display(false)
        choose()
    }

    const modal6On = emby => event => {

        event.preventDefault()

        if ({jurg}.jurg == false) {
            choose(emby.html)
            info(emby.frontmatter)
            displayM6(true)
          } else {
            displayM6(false)
          }
    
    }

    const modal6Off = (event) => {
        event.preventDefault()

        displayM6(false)
    }

    const refac = props.data.allMarkdownRemark.edges

    //map for query data and sort it etc
    const items = refac.map((el, key)=>{

        //this if statement basically happens because of the overlay class
        //note the hacky execeptions for the empty black squares
        if (props.width <= 768) {

            if (key === 6) { 

                return (

                    <div onClick={modal6On(el.node)} style={innerDivStyle} key={key}>
        
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
        
                    </div>
        
                )
            } else {

                return (

                    <div onClick={key === 17 ? "" : modalOn(el.node)} style={innerDivStyle} key={key}>
        
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
        
                    </div>
        
                )

            }

        } else {

            if (key === 6) {

                return (

                    <div onClick={modal6On(el.node) } style={innerDivStyle} key={key}>
    
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
                        <div className={key !== 14 ? "overlay": ""}>
                            <p className="gridText" id={key}>
                                {el.node.frontmatter.title}
                                <br />
                                {el.node.frontmatter.company != "none" ? el.node.frontmatter.company : " "}
                                {el.node.frontmatter.company ? <br /> : " "}
                                {el.node.frontmatter.director}
                                <br />
                                {el.node.frontmatter.producer}
                                <br />
                                {el.node.frontmatter.extra ? el.node.frontmatter.extra : " "}
                                {el.node.frontmatter.extra ? <br /> : " "}
                                <i>{props.lang == "ES" ? el.node.frontmatter.genero : el.node.frontmatter.genre}</i>
                            </p>
                        </div>
    
                    </div>
        
                )

            } else if (key === 4) {

                return (

                    <div onClick={key === 17 ? "" : modalOn(el.node) } style={innerDivStyle} key={key}>
        
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
                        <div className={key !== 17 ? "overlay": ""}>
                            <p className="gridText" id={key}>
                                {el.node.frontmatter.title}<br />
                                {el.node.frontmatter.sub}
                                <br />
                                {el.node.frontmatter.director != "none" ? el.node.frontmatter.director : " "}
                                {el.node.frontmatter.director != "none" ? <br /> : " "}
                                {el.node.frontmatter.producer != "none" ? el.node.frontmatter.producer : " "}
                                {el.node.frontmatter.producer != "none" ? <br /> : " "}
                                {el.node.frontmatter.company != "none" ? el.node.frontmatter.company : " "}
                                {el.node.frontmatter.company != "none" ? <br /> : " "}
                                <i>{props.lang == "ES" ? el.node.frontmatter.genero : el.node.frontmatter.genre}</i>
                            </p>
                        </div>
        
                    </div>
        
                )

            } 

            else {

                return (

                    <div onClick={key === 17 ? "" : modalOn(el.node)} style={innerDivStyle} key={key}>
        
                        <Img style={picCont} imgStyle={picStyle} fluid={el.node.frontmatter.featuredImage.childImageSharp.fluid} alt={el.node.frontmatter.title} />
                        <div className={key !== 17 ? "overlay": ""}>
                            <p className="gridText" id={key}>
                                {el.node.frontmatter.title}
                                <br />
                                {el.node.frontmatter.director != "none" ? el.node.frontmatter.director : " "}
                                {el.node.frontmatter.director != "none" ? <br /> : " "}
                                {el.node.frontmatter.producer != "none" ? el.node.frontmatter.producer : " "}
                                {el.node.frontmatter.producer != "none" ? <br /> : " "}
                                {el.node.frontmatter.company != "none" ? el.node.frontmatter.company : " "}
                                {el.node.frontmatter.company != "none" ? <br /> : " "}
                                <i>{props.lang == "ES" ? el.node.frontmatter.genero : el.node.frontmatter.genre}</i>
                            </p>
                        </div>
        
                    </div>
        
                )

            }

        }

    })
    
    return [ 
        
        <HeaderDecider />,
        <div style={gridStyle}>
            {items}
        </div>,
        <Modal off={modalOff} display={modal} vid={embed} info={mobileInf}/>,
        <Jurg off={modal6Off} display={jurg} info={mobileInf} vid={embed} />,
        
    ]

}

const mapStateToProps = (state) => {
    return {
        lang: state.lang,
        width: state.width,
        height: state.height
    }
}

export default connect(mapStateToProps)(Work)

export const pageQuery = graphql `{
    allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/work/"}}, sort: {fields: frontmatter___order, order: ASC}) {
        edges {
          node {
            frontmatter {
                title,
                sub,
                company,
                director,
                producer,
                genre,
                genero,
                featuredImage {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                        }
                        
                }
              }
            }
            html
          }
        }
      }  
} 
`