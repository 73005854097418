import React, { useRef, useEffect, useState } from "react"
import { connect } from "react-redux"
import Close from "../images/close.png"

const Modal = props => {

    const showHide = props.display ? 'block' : 'none'

    useEffect(()=>{

        if (props.display) {

            document.body.style.overflow = `hidden`
    
        } else {
    
            document.body.style.overflow = `auto`
    
        }

    }, [props.display])

    const modalStyle = {
        color: `white`,
        background: `black`,
        //minHeight: props.height,
        display: showHide,
        position: 'fixed',
        zIndex: 5,
        top: 0,
        left: 0,
        //width: `100%`,
        width: props.width,
        height: `100%`,
        overflow: `auto`
    }

    const divStyle = {
        paddingTop: `100px`,
        textAlign: `center`
    }

    const closeStyle = {
        float: `right`,
        paddingTop: 15,
        paddingRight: 25
    }

    const mobCloseStyle = {
        float: `right`,
        paddingTop: 15,
        paddingRight: 25
    }

    const mobEncloser = {
        position: `absolute`,
        paddingTop: `100px`,
        paddingLeft: `30px`,
        fontSize: `13px`,
    }

    if (props.width <= 768) {

        return (

            <div style={modalStyle}>

                <div className="pointer" style={mobCloseStyle}><img width="15px" onClick={props.off} src={Close}></img></div>

                <div style={mobEncloser}>

                    <div dangerouslySetInnerHTML={{__html: props.vid}}></div>

                    <div>
                        <p>
                            {props.info.title}
                            {props.info.sub ? <br /> : " "}
                            {props.info.sub ? props.info.sub : " "}
                            <br />
                            {props.info.company != "none" ? props.info.company : " "}
                            {props.info.company != "none" ? <br /> : " "}
                            {props.info.director ? props.info.director : " "}
                            {props.info.director ? <br /> : " "}
                            {props.info.extra ? props.info.extra : " "}
                            {props.info.extra ? <br /> : " "}
                            <i>{props.lang == "ES" ? props.info.genero : props.info.genre}</i>
                        </p>
                    </div>

                </div>

            </div>

        )

    } else {

        return (

            <div style={modalStyle}>

                <div onClick={props.off} className="pointer" style={closeStyle}><img width="10px" src={Close}></img></div>
                <div style={divStyle} dangerouslySetInnerHTML={{__html: props.vid}}></div>
                
            </div>

        )

    }

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height
    }
  }
  
export default connect(mapStateToProps)(Modal)
