import React, { useRef, useState, useLayoutEffect } from "react"
import { connect } from "react-redux"
import Close from "../../images/bclose.png"
import White from "../../images/lazy.jpg"

const Jurg = props => {

    const [firstRect, styleTitle] = useState({display: `none`})
    const [embedRect, styleGraf] = useState({display: `none`})

    useLayoutEffect(()=>{

        if (props.display) {

            document.body.style.overflow = `hidden`
            styleTitle({marginLeft: one.current.getBoundingClientRect().left, textAlign: `left`})
            styleGraf({marginLeft: four.current.getBoundingClientRect().left, textAlign: `left`})

        } else {
    
            document.body.style.overflow = `auto`
    
        }

    }, [props.display])

    const one = useRef("one")
    const two = useRef("two")
    const three = useRef("three")
    const four = useRef("four")
    const five = useRef("five")
    const six = useRef("six")
    const seven = useRef("seven")
    const eight = useRef("eight")
    const nine = useRef("nine")
    const ten = useRef("ten")
    const eleven = useRef("eleven")
    
    const showHide = props.display ? 'block' : 'none'

    useLayoutEffect(()=>{

        const lazies = [one,two,three,four,five,six,seven,eight,nine,ten,eleven]

        const options = {
            root: null,
            rootMargin: '0px',
            threshold: [0.10, 0.20, 0.30, 0.40, 0.50, 0.60, 0.70, 0.80, 0.90, 1.00]
        }

        const lazyImageObserver = new IntersectionObserver(analies, options);

        function analies (entries, observer) {

            entries.map( entry => {

                if (entry.isIntersecting) {
    
                    entry.target.src = entry.target.dataset.src;
                            
                }

            })
    
        }

        lazies.map(lazy=>{

            lazyImageObserver.observe(lazy.current)

        })

    }, [])

    const modalStyle = {
        top: 0,
        left: 0,
        color: `black`,
        background: `white`,
        display: showHide,
        position: `fixed`,
        zIndex: 5,
        width: props.width,
        // height: props.height, need to fix mob header thing
        height: `100%`, //temporary
        overflow: `auto`,
        fontSize: `11px`,
        textAlign: `center`
    }

    const closeStyle = {
        float: `right`,
        paddingTop: 15,
        paddingRight: 30,

    }

    let divStyle

    let picStyle

    let grafStyle

    if (props.width <= 1024 && props.width > 500) {

        divStyle = {
            paddingTop: `75px`,
            position: `static`,
        }

        picStyle = {

            paddingBottom: `50px`,
            width: `300px`,
            position: `static`,
        }

        grafStyle = {
            width: `320px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else if (props.width <= 500) {

        divStyle = {
            paddingTop: `50px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `250px`,
            position: `static`,
        }

        grafStyle = {
            width: `190px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    } else {

        divStyle = {
            paddingTop: `100px`,
            position: `static`,
        }

        picStyle = {
            paddingBottom: `50px`,
            width: `500px`,
            position: `static`,
        }

        grafStyle = {
            width: `380px`,
            paddingBottom: `50px`,
            ...embedRect
        }

    }
   
    return [
        <div style={modalStyle}>
            <div onClick={props.off} className="pointer" style={closeStyle}><img width="10px" src={Close}></img></div>
            <div style={divStyle}>
                <p style={firstRect}>{props.info.title}</p>
                <br />
                <img ref={one} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/1.jpg`} alt="1"></img>
                <br />
                <img ref={two} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/2.jpg`} alt="2"></img>
                <br />
                <img ref={three} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/3.jpg`} alt="3"></img>
                <br />
                <img ref={four} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/4.jpg`} alt="4"></img>
                <br />
                <img ref={five} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/5.jpg`} alt="5"></img>
                <br />
                <img ref={six} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/6.jpg`} alt="6"></img>
                <br />
                <img ref={seven} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/7.jpg`} alt="7"></img>
                <br />
                <img ref={eight} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/8.jpg`} alt="8"></img>
                <br />
                <img ref={nine} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/9.jpg`} alt="9"></img>
                <br />
                <img ref={ten} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/10.jpg`} alt="10"></img>
                <br />
                <img ref={eleven} style={picStyle} src={White} data-src={`https://d975adv1u4h02.cloudfront.net/test/work/jurg/11.jpg`} alt="11"></img>
                <br />
            </div>
        </div>
    ]

}

const mapStateToProps = (state) => {
    return {
        width: state.width,
        height: state.height,
        lang: state.lang
    }
  }
  
export default connect(mapStateToProps)(Jurg)